export default class MenuTableItem {
    menuId = 0;
    menuStatus = 0;
    menuParentId = 0;
    menuParentName = "";
    menuTypeId: string | number = 0;
    menuTypeIdName = "";
    menuSortId = 0;
    menuPath = "";
    menuName = "";
    menuIcon = "";
    menuComponent = "";
    isView = 0;
    isViewName = "";
    isAuth = 0;
    isAuthName = "";
    isCache = 0;
    isCacheName = "";
    isDefaultAction = 0;
    isDefaultActionName = "";
    menuRemark = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
