import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import SelectLevel from "@/modules/select-level/select-level.vue";
import DraggableListview from "@/modules/draggable-listview/draggable-listview.vue";
import MenuTableItem from "@/entity/system/master/MenuTableItem";
import API from "@/api/system/master/menu";

@Options({
    name: "app-system-master-menu",
    components: {
        "app-select-level": SelectLevel,
        "app-draggable-listview": DraggableListview,
    },
})
export default class Menu extends BaseTableMixins<MenuTableItem> implements Partial<BaseTableInterface<MenuTableItem>> {
    private menuTypeList = [
        { value: "1", label: "菜单" },
        { value: "2", label: "模块" },
    ];

    public created(): void {
        this.setInterface(this);
        this.tableRequestItem.lockItem = this.getLockItem();
        this.editTableItem = new MenuTableItem();
        this.editModalItem = new MenuTableItem();
    }

    //锁定列数据实体
    public getLockItem(): any {
        return {
            menuParentId: {
                type: "router",
                name: "menu_id",
                title: "上级菜单",
                parent: -1,
                value: null,
                openFn: this.menuParentIdLevelModalOpen,
            },
        };
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    // //列表回调
    // public listCallback(items: Array<MenuTableItem>): void {
    //     return;
    // }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new MenuTableItem();
        this.editModalItem = new MenuTableItem();
        this.editModalItem.menuTypeId = "1";
        this.editModalItem.menuStatus = 1;
        this.editModalItem.isView = 1;
        this.editModalItem.isAuth = 1;
        this.editModalItem.isCache = 1;
        this.editModalItem.isDefaultAction = 1;
        this.editModalTitle = "添加菜单";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.menuTypeId)) return "菜单类型不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.menuParentId)) return "上级模块不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.menuStatus)) return "状态不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.menuName)) return this.menuTypeName + "名称不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.menuPath)) return this.menuTypeName + "路径不能为空！";
        if (this.editModalItem.menuTypeId === "1") {
            if (this.$tools.isEmpty(this.editModalItem.isAuth)) return "是否认证不能为空！";
            if (this.$tools.isEmpty(this.editModalItem.isCache)) return "是否缓存不能为空！";
            if (this.$tools.isEmpty(this.editModalItem.isDefaultAction)) return "是否默认功能不能为空！";
        }
        return null;
    }
    // //添加回调
    // public addModalCallback(res: any): void {
    //     return;
    // }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalItem.menuTypeId += "";
        this.editModalTitle = "修改菜单 (" + this.editModalItem.menuName + ")";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.menuId)) return "菜单ID不能为空！";
        return this.addSubmitChecking();
    }
    // //修改回调
    // public modModalCallback(res: any): void {
    //     return;
    // }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除菜单",
            message: "菜单名称: " + this.editModalItem.menuName + "<br>请确认删除？",
        };
    }
    // //删除提示框配置
    // public delAlertSetting(): void {
    //     return;
    // }
    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.menuId)) return "菜单ID不能为空！";
        return null;
    }
    // //删除回调
    // public delAlertCallback(res: any): void {
    //     return;
    // }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "菜单状态",
            message: "菜单名称: " + this.editModalItem.menuName + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.menuStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.menuStatus = this.editModalItem.menuStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.menuId)) return "菜单ID不能为空！";
        return null;
    }
    // //状态切换回调
    // public toggleStatusCallback(): void {
    //     return;
    // }

    //排序弹窗配置
    public sortModalSetting(): void {
        this.editSortBaseTitle = "菜单排序";
    }
    //排序提交检测
    public sortSubmitChecking(): string {
        if (this.editModalItem.menuParentId < -1) {
            this.toast.error("上级ID不能小余-1");
            return;
        }
        return null;
    }

    //menuParentId搜索字段
    private menuParentIdLevelSearchField: any = [
        { field: "menu_name", name: "名称" },
        { field: "menu_remark", name: "说明" },
    ];
    //menuParentId层级选择器打开
    public menuParentIdLevelModalOpen() {
        (this.$refs.menuParentIdLevel as any).openModal();
    }
    //menuParentId层级选择器提交
    public async menuParentIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().menuParentId : item;
        this.tableRequestItem.lockItem.menuParentId.value = curItem.value;
        this.tableRequestItem.lockItem.menuParentId.title = curItem.title;
        this.tableRequestItem.lockItem.menuParentId.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    get menuTypeName() {
        return this.editModalItem.menuTypeId === "1" ? "菜单" : "模块";
    }

    //模块选择器
    get menuIdSelectApi() {
        return {
            api: (query: any) => API.idSelect(query),
            parent: "menu_parent_id",
            status: "menu_status",
            name: "menu_name",
            id: "menu_id",
            cid: this.editModalItem.menuParentId,
            mid: this.editModalItem.menuId,
            cType: 2,
        };
    }
}
